import { filter, withLatestFrom, switchMap, concat, mergeMap, catchError, of } from 'rxjs';

import { LuotXeActions } from './luotxeSlice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';
import { GetLuotXeByDate, GettingLuotXeList } from '@/common/define';
import { LuotXeService } from '@/services/LuotXe.service';
import Utils from '@/utils';

const getLuotXesRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXe.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, options } = action.payload;
      return concat(
        [startLoading({ key: GettingLuotXeList })],
        LuotXeService.Get.getReportByDate(projectId, options).pipe(
          mergeMap(LuotXes => {
            return of(LuotXeActions.setLuotXe(LuotXes));
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXe(undefined)];
          }),
        ),
        [stopLoading({ key: GettingLuotXeList })],
      );
    }),
  );
};

const getTongHopLuotXe$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getTongHopLuotXe.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, day, options, search } = action.payload;
      console.log('Action Payload:', action.payload);
      return concat(
        of(startLoading({ key: 'tong hop luot xe' })),

        LuotXeService.Get.getTongHopLuotXe(projectId, day, options).pipe(
          switchMap(response => {
            return of(
              LuotXeActions.setTongHopLuotXe(response),
              LuotXeActions.getLuotXe({
                projectId,
                options: {
                  ...options,
                  search: { startDate: day, endDate: day },
                },
              }),
              LuotXeActions.getLuotXeByDate({
                projectId,
                options: { search: search },
              }),
              LuotXeActions.getLuotXeMaHang({ projectId, date: day, options: {} }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return of(LuotXeActions.setTongHopLuotXe(null));
          }),
        ),
        of(stopLoading({ key: 'tong hop luot xe' })), // Kết thúc loading
      );
    }),
  );
};

const getLuotXeByDateRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeByDate.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, options } = action.payload;
      return concat(
        [startLoading({ key: GetLuotXeByDate })],
        LuotXeService.Get.getReportByDate(projectId, options).pipe(
          mergeMap(LuotXes => {
            return [LuotXeActions.setLuotXeByDate(LuotXes)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXeByDate(undefined)];
          }),
        ),
        [stopLoading({ key: GetLuotXeByDate })],
      );
    }),
  );
};

const updateLuotXeRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.updateLuotXe.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, data, options } = action.payload;
      console.log(data);
      return concat(
        [startLoading({ key: GetLuotXeByDate })],
        LuotXeService.Put.updateLuotXeTheoNgay(data.id, data).pipe(
          mergeMap(() => {
            return [LuotXeActions.getLuotXeDay({ projectId, options })];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: GetLuotXeByDate })],
      );
    }),
  );
};

const updateTicketRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.updateTicket.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const {projectId, day, data, options, search } = action.payload;
      console.log(data);
      return concat(
        [startLoading({ key: GetLuotXeByDate })],
        LuotXeService.Put.updateTicket(data).pipe(
          mergeMap(() => {
            return [
              LuotXeActions.getTongHopLuotXe({ projectId,  day, options, search }),
              
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: GetLuotXeByDate })],
      );
    }),
  );
};

const getLuotXeTheoNgay$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeDay.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, options } = action.payload;

      return concat(
        LuotXeService.Get.getLuotXeTheoTime(projectId, options).pipe(
          mergeMap(luotXeTheoNgay => {
            return of(LuotXeActions.setLuotXeTheoNgay(luotXeTheoNgay));
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return of(LuotXeActions.setLuotXeTheoNgay(undefined));
          }),
        ),
      );
    }),
  );
};

const getLuotXeMaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(LuotXeActions.getLuotXeMaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, date, options } = action.payload;
      return concat(
        [startLoading({ key: 'luot xe theo ma hang' })],
        LuotXeService.Get.getLuotXeMaHang(projectId, date, options).pipe(
          mergeMap(LuotXe => {
            return concat(of(LuotXeActions.setLuotXeMaHang(LuotXe)));
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [LuotXeActions.setLuotXeMaHang(undefined)];
          }),
        ),
        [stopLoading({ key: 'luot xe theo ma hang' })],
      );
    }),
  );
};

export const LuotXeEpics = [
  getTongHopLuotXe$,
  getLuotXeTheoNgay$,
  getLuotXesRequest$,
  getLuotXeByDateRequest$,
  getLuotXeMaHang$,
  updateLuotXeRequest$,
  updateTicketRequest$,
];
