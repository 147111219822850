import { useEffect, useState } from 'react';

import {
  RightOutlined,
  LeftOutlined,
  HomeOutlined,
  SettingOutlined,
  AppstoreOutlined,
  DashboardOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Layout, Menu, MenuProps, SiderProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './LeftSider.module.less';
import './leftPanel.css';
import { LeftPanelWidth, MenuItem } from '@/common/define';
import { getEnvVars } from '@/environment';
import ProjectBg from '@/image/icon/project.png';
import { appActions, getActiveMenu } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getSelectedProject, projectActions } from '@/store/project';

const { Sider } = Layout;

const { apiUrl } = getEnvVars();

export const LeftSider = (props: SiderProps) => {
  const { ...rest } = props;

  const { t } = useTranslation(['layout', 'organization']);

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const activeMenu = useAppSelector(getActiveMenu());

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState(['/members']);
  const [showMenu, setShowMenu] = useState(false);

  const mainMenu: MenuItem[] = [
    // {
    //   label: t('Home'),
    //   icon: <FileDoneOutlined />,
    //   key: '/',
    // },
    {
      label: t('Project'),
      icon: <HomeOutlined />,
      key: '/',
    },
  ];

const projectMenu: MenuItem[] = [
  {
    label: t('Dashboard'),
    icon: <DashboardOutlined />,
    key: '/project/dashboard',
  },
  {
    label: t('Vehicle In and Out'),
    icon: <AppstoreOutlined />,
    key: '/vehicle',
    children: selectedProject && selectedProject.id === 1
      ? [
          {
            label: t('PortOne'),
            key: '/project/vehicle/portoneday',
          },
          {
            label: t('PortTwo'),
            key: '/project/vehicle/porttwoday',
          },
          {
            label: t('PortThree'),
            key: '/project/vehicle/portthreeday',
          },
        ]
      : [
          {
            label: t('PortOne'),
            key: '/project/vehicle/portoneday',
          },
          {
            label: t('PortTwo'),
            key: '/project/vehicle/porttwoday',
          },
        ],
  },
  ...(selectedProject && selectedProject.id === 1
    ? [
        {
          label: t('Weight List'),
          icon: <SnippetsOutlined />,
          key: '/project/WeightList',
        },
      ]
    : []),
];

  const settingsMenu: MenuItem[] = [
    {
      label: t('Settings'),
      icon: <SettingOutlined />,
      key: '/project/project-settings',
    },
  ];

  const ipSpecificMenu: MenuItem[] = [
    {
      label: t('Vehicle Management'),
      icon: <AppstoreOutlined />,
      key: '/project/vehicle',
      children: [
        {
          label: t('PortOne'),
          key: '/project/vehicle/portone',
        },
        {
          label: t('PortTwo'),
          key: '/project/vehicle/porttwo',
        },
      ],
    },
    {
      label: t('Weight List'),
      icon: <SnippetsOutlined />,
      key: '/project/WeightList',
    },
  ];

  useEffect(() => {
    const { pathname } = location;
    if (location.pathname === '/project') {
      dispatch(projectActions.setSelectedProject(undefined));
    }
    const isLocal = window.location.hostname === '' || window.location.hostname === '';
    if (isLocal) {
      setShowMenu(false);
    } else {
      const userIp = window.location.hostname;
      if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(userIp)) {
        const fakeProject: any = {
          id: 1,
          name: 'Mỏ Hoà Bình',
          location: '123 Fake Street',
          startDate: new Date().toISOString(),
          status: 'active',
        };
        dispatch(projectActions.setSelectedProject(fakeProject));
        setShowMenu(true);
        const isPathInIpMenu = ipSpecificMenu.some(item => {
          if (item?.key === pathname) return true;

          if ('children' in item! && Array.isArray(item.children)) {
            return item.children.some(child => child?.key === pathname);
          }

          return false;
        });
        if (!isPathInIpMenu) {
          navigate('/project/WeightList');
        }
      } else {
        setShowMenu(false);
      }
    }
    const menus: any = showMenu
      ? ipSpecificMenu
      : projectMenu
          .filter(item => item?.key !== '/project/WeightList')
          // .concat(settingsMenu)
          .concat(mainMenu);
    for (const item of menus) {
      if (item?.key === pathname) {
        const { label, key } = item;
        dispatch(appActions.setActiveMenu({ label, key }));
      }
      if (item?.children) {
        for (const child of item.children) {
          if (child.key === pathname) {
            const { label, key } = child;
            dispatch(appActions.setActiveMenu({ label, key }));
            if (!collapsed) {
              setOpenKeys([item.key]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, collapsed, showMenu]);
  const onClickMenu = (menu: any) => {
    const { key } = menu;
    navigate(key);
  };

  const onOpenChange: MenuProps['onOpenChange'] = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const handleLeftPanelVisibility = () => {
    setCollapsed(prev => !prev);
  };

  return (
    <Sider
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={LeftPanelWidth}
      onCollapse={setCollapsed}
      className={styles.main}
      {...rest}
    >
      <div className={styles.menusWrapper}>
        <div
          style={{
            display: 'flex',
            margin: selectedProject ? 5 : 0,
            height: !selectedProject ? (collapsed ? 40 : 0) : collapsed ? 30 : 65,
          }}
        >
          {selectedProject && (
            <>
              <Avatar
                shape="square"
                size={64}
                src={selectedProject?.avatar ? `${apiUrl}${selectedProject?.avatar}` : ProjectBg}
                style={{ display: collapsed ? 'none' : 'block' }}
              />
              <div style={{ marginLeft: 5, paddingTop: 5, display: collapsed ? 'none' : 'block' }}>
                <b>{selectedProject?.name}</b>
              </div>
            </>
          )}
          <Button
            shape="circle"
            size="small"
            onClick={handleLeftPanelVisibility}
            className={styles.toggleButton}
            icon={collapsed ? <RightOutlined style={{ fontSize: 11 }} /> : <LeftOutlined style={{ fontSize: 11 }} />}
            style={{ transform: collapsed ? 'translateX(-110%)' : 'translateX(-10%)' }}
          />
        </div>
        {!selectedProject && (
          <Menu
            mode="inline"
            onClick={({ key }) => {
              navigate(key);
            }}
            selectedKeys={[activeMenu?.key]}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={mainMenu}
            inlineIndent={10}
            className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
          />
        )}
        {selectedProject && (
          <>
            <Menu
              mode="inline"
              onClick={onClickMenu}
              selectedKeys={[activeMenu?.key]}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              items={showMenu ? ipSpecificMenu : projectMenu.filter(item => item?.key !== '/WeightList')}
              inlineIndent={5}
              className={`${styles.top_menu} ${styles.custom_scrollbar} left_menu`}
            />
            {/* {!showMenu && (
              <Menu
                mode="inline"
                onClick={onClickMenu}
                selectedKeys={[activeMenu?.key]}
                items={settingsMenu}
                inlineIndent={5}
                className={styles.bottom_menu}
              />
            )} */}
          </>
        )}
      </div>
    </Sider>
  );
};
