import { Observable } from 'rxjs';

import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();
export interface LuotXeSummarize {
  dateSummarizes: {
    date: string;
    tong: {
      luotXeAccess: number;
      luotXe: number;
      soCan: number;
      soCanAccess: number;
    };
    phieuRa: number;
    phieuVao: number;
  }[];
  tong: {
    luotXeAccess: number;
    luotXe: number;
    soCan: number;
    soCanAccess: number;
  };
  phieuRa: number;
  phieuVao: number;
  gateSummarize: {
    gate: string;
    phieuRa: number;
    phieuVao: number;
    errorVao: number;
    errorRa: number;
  }[];
}
export interface DateSummarize {
  dateSummarizes: {
    maHang: string | null; // Thuộc tính maHang
    luotXe: number; // Số lượt xe
    soCan: number; // Số cân
  }[];
  tong: {
    luotXe: number; // Tổng số lượt xe
    soCan: number; // Tổng số cân
  };
  phieuRa: number; //  phiếu ra
  phieuVao: number; //  phiếu vào
}

export interface LuotXeTheoNgayResponse {
  results: LuotXeTheoNgay[];
  page: number;
  pageCount: number;
  pageSize: number;
  queryCount: number;
  firstRowIndex: number;
  lastRowIndex: number;
}

export interface Ticket {
  date_Key: string;          // Date as a string, e.g., "20241023"
  detectedObjectId: string;  // Vehicle identifier as a string
  gate: string;              // Gate identifier as a string
  loaiXe?: string | null;    // Type of vehicle, nullable
  maHang?: string | null;    // Goods code, nullable
  projectId: number;         // Project ID as a number
  sectionId: string;         // Section ID as a string
  thungXeImage?: string | null;  // Truck image, nullable
  triggerImage: string;      // Trigger image path as a string
  triggerTimestamp: string;  // Timestamp as a string
  trigger_code: string;      // Trigger code as a string
  type: number;              // Type, 1 for outbound, 0 for inbound
  weight: number;            // Weight as a number
  weightImage?: string | null;   // Weight image path, nullable
  score_matching: number;
  best_match_lp: string;
  raw_license_plate: string;
  note?: string;
}

export interface LuotXeTheoNgay {
  date_Key: number;
  detectedObjectId: string;
  triggerImage: string;
  type: number;
  maHang: string;
  weight: number;
  thungXeImage: string;
  weightIn: number;
  weightOut: number;
  timestampOut: string;
  timestampIn: string;
  loaiXe: string;
  id: number;
  status: string | null | undefined;
  key: number;
  ticketOut: Ticket;
  ticketIn: Ticket;
  score_matching: number;
  best_match_lp: string;
  raw_license_plate: string;
}
export interface UpdateLuotXeTheoNgay {
  maHang?: string;
  weight?: number;
  weightIn?: number;
  weightOut?: number;
  loaiXe?: string;
  status?: string | null | undefined;
  note?: string;
  id: number;
}
class LuotXeController {
  public Get = {
    getReportByDate: (projectId: number, options?: RequestOptions): Observable<LuotXeSummarize> => {
      return HttpClient.get(`${apiUrl}/LuotXe/report/${projectId}`, options);
    },
    getLuotXeTheoNgay: (
      projectId: number,
      day: string,
      options?: RequestOptions,
    ): Observable<LuotXeTheoNgayResponse> => {
      return HttpClient.get(`${apiUrl}/LuotXe/project/${projectId}/date/${day}`, options);
    },
    getLuotXeTheoTime: (
      projectId: number,
      options?: RequestOptions,
    ): Observable<LuotXeTheoNgayResponse> => {
      return HttpClient.get(`${apiUrl}/LuotXe/project/${projectId}/time`, options);
    },
    getLuotXeMaHang: (projectId: number, date: string, options?: RequestOptions): Observable<DateSummarize> => {
      return HttpClient.get(`${apiUrl}/LuotXe/report/${projectId}/mahang?date=${date}`, options);
    },
    getTongHopLuotXe: (projectId: number, day: string, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/LuotXe/project/${projectId}/${day}`, options);
    },
  };
  public Put = {
    updateLuotXeTheoNgay: (Id: number, data: UpdateLuotXeTheoNgay): Observable<LuotXeTheoNgay> => {
      return HttpClient.put(`${apiUrl}/LuotXe/${Id}/status`, data);
    },
    updateTicket: (data: Ticket): Observable<Ticket> => {
      return HttpClient.put(`${apiUrl}/Ticket`, data);
    },
  };
}
export const LuotXeService = new LuotXeController();
